.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.title {
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  margin-bottom: 0.5rem;
}

.field {
  border: none;
  padding: 10px 28px;
  border-radius: 32px;
  background-color: #f6f6f6;
  position: relative;
}

.addon {
  position: absolute;
  top: 11px;
  left: 28px;
  font-size: 20px;
  margin: 0px;
}
