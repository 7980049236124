.container {
  max-width: 100px;
  display: table;
  border-radius: 32px;
  background-color: #e3581c;
  border: 2px solid #e3581c;
  padding: 8px 22px;
  color: white;
  font-size: 20px;
  line-height: 32px;
  margin: 2rem auto;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: #e3581c;
  }
}
