body {
  margin: 0;
  font-family: "Titillium Web", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  font-family: Varela Round, Helvetica, Arial, sans-serif;
}

/* Styles */

.wrapper {
  display: flex;
  padding: 3rem;
}

.container {
  width: 100%;
  max-width: 600px;
}

.table {
  width: calc(100% - 3rem);
  max-width: 1000px;
  margin-left: 4rem;
  border-left: 3px solid #f6f6f6;
  padding-left: 3rem;
}

p.home_headerNumber {
  font-weight: bold;
  text-align: right;
  margin: 0px;
}
