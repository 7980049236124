.line_label {
  display: flex;
  align-items: center;
}

.line_container p {
  margin: 0 1rem 0 0;
}

.line_subContainerOpen td {
  background-color: #f6f6f6;
  height: 100%;
  transition: all 0.3s linear;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.line_subContainer p {
  font-size: 14px;
  margin: 0px;
}

.line_subContainerClosed td {
  background-color: #f6f6f6;
  height: 0%;
  overflow: hidden;
  transition: all 0.2s linear;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: solid 0px #f6f6f6;
}

.line_subContainerClosed p {
  font-size: 0px !important;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.line_subContainerOpen p {
  font-size: 14px;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.line_icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.line_iconOpen {
  transform: rotate(-360deg); /* Equal to rotateZ(45deg) */
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
  color: gray;
}

.line_iconClosed {
  transition: transform 0.3s ease-in-out;
  color: #e3581c;
}

p.line_number {
  text-align: right;
  margin: 0px;
}
