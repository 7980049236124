.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.title {
  font-size: 17px;
  font-weight: 500;
  line-height: 25.5px;
  margin-bottom: 0.5rem;
}

.field {
  border: none;
  padding: 10px 28px;
  border-radius: 32px;
  background-color: #f6f6f6;
  background-image: url("../../assets/img/arrow_down.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 28px) 50%;
}

.select {
  &:after {
    border-bottom-color: "darkred";
  }
  & .MuiSvgIcon-root {
    color: "darkred";
  }
}
